.page {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;

  > * {
    flex: 1;
  }
  @media (min-width: $bp-xl) {
    flex-direction: row;
  }
}

.sidebar {
  background: $grey-10;
  position: relative;

  @media (min-width: $bp-xl) {
    flex-grow: 0;
    flex-basis: 15rem;
  }

  &__org {
    padding: 20px;
    border-bottom: $border;
    line-height: 1.4rem;
    font-size: 14px;

    &--name {
      font-size: 16px;
      display: block;
      font-weight: bold;
      margin-bottom: 4px;
    }

    &--abn,
    &--branch {
      color: $grey;
    }
  }

  &__links {
    margin-top: 1rem;

    li {
      margin-bottom: 0.25rem;
    }
  }

  button, a {
    border: none;
    background: none;
    display: block;
    // width: 90%;
    margin-right: 1rem;
    padding: 0.875rem 1.25rem;
    border-radius: 0 1.5rem 1.5rem 0;
    color: $black;
    transition: all 0.5s ease-out;
    &:hover {
      text-decoration: none;
      background: $grey-20;
    }
  }

  .is-active {
    background: $yellow;
    &:hover {
      background: $yellow;
    }
  }

  .clicksuper-link {
    border-top: $border;
    margin-top: 1rem;
    padding-top: 1rem;

    // @media (min-width: $bp-xl) {
    //   position: fixed;
    //   bottom: 0;
    //   width: 240px;
    // }

    // a {
    //   padding-right: 0;
    //   padding-top: 1rem;
    //   padding-bottom: 1rem;
    //   display: flex;
    //   margin-right: 0;
    //   border-radius: 0;
    //   cursor: pointer;
    // }

    button, a {
      display: flex;
      align-items: center;
    }
  }
}

.main {
  padding: 1rem;

  @media (min-width: $bp-lg) {
    padding: 1.5rem 2.5rem 0 2.5rem;
  }
}

.content-container {
  padding-top: 1rem;
}

.section-container {
  @include border-radius;
  max-width: 36rem;
  padding: 2rem;
  border: 1px solid $grey-30;
  margin-bottom: 2rem;
}

.page__background {
  width: 100%;
  min-height: 100vh;
  padding: 4rem 2rem;
  background: $grey-10;
  text-align: center;
}
