.setup-tasks {
    background: $wrkr-yellow;
    position: relative;
    padding: 20px;

    @media (min-width: $bp-xl) {
        flex-grow: 0;
        flex-basis: 20rem;
    }

    .tasks {
        display: flex;
        flex-direction: column;
        background: $white;
        padding-bottom: 20px;
        border: 1px solid #33333366;
        border-radius: 5px;
        box-shadow: 0px 2px 4px 1px #0000001A;

        .tasks-title {
            padding: 20px;
            padding-bottom: 0px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);

            .dismiss-icon {
                background-image: url("../../assets/images/icons/cross.svg");
                width: 24px;
                height: 24px;
                position: relative;
                bottom: 11px;
                left: 11px;
                float: right;
                cursor: pointer;
            }

            .tasks-header {
                font-family: Rubik;
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: left;
                margin-bottom: 0;
            }

            .tasks-desc {
                font-family: Rubik;
                font-size: 22px;
                font-weight: 300;
                line-height: 33px;
                letter-spacing: 0em;
                text-align: left;
            }
        }

        .tasks-content {
            .task-item {
                padding: 24px;
                height: 40px;
                border-bottom: 1px solid $task-border;

                .task-item-check {
                    text-align: center;
                    flex: 1;

                    &.check {
                        color: $check;
                    }

                    &.uncheck {
                        color: $uncheck;
                    }
                }

                .task-item-title {
                    margin-left: 1em;
                    flex: 10;
                }

                .task-item-exclamation {
                    color: $required;
                    text-align: center;
                    flex: 1;
                }
            }
        }
    }

    .button--link {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, -50%);
        background: none;
        border: none;
        color: #333333CC;
        text-decoration: underline;
        cursor: pointer;
        padding: 0;
        margin: 0;
        outline: none;
        transition: color 0.3s ease;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;

        &:hover,
        &:focus {
            color: darkblue;
            text-decoration: none;
        }
    }
}