.steppers {
  display: flex;
  justify-content: space-between;
  width: 26rem;
  margin: 0.75rem 0;
  padding: 0;
  list-style: none;
  counter-reset: step;
}

.steppers__step {
  display: flex;
  padding: 0 0.5rem 0 0;
  align-items: center;
  counter-increment: step;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out;
  font-size: 1.125rem;

  .button {
    padding: 0.5rem;

    color: inherit;

    &:disabled {
      opacity: 0.8;
      &:hover {
        text-decoration: none;
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &:not(:last-of-type) {
    flex: 1;
  }

  &:before {
    content: counter(step);
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    line-height: 2.5rem;
    border: 1px solid $grey-50;
    border-radius: 50%;
    color: $black;
    transition: all 0.2s ease-in-out;
  }

  &.is-active {
    opacity: 1;

    &:before {
      background: $black;
      color: #fff;
    }

    .button {
      color: $black;
      // cursor: default;
      // &:hover {
      //   text-decoration: none;
      // }
    }
  }

  &.is-complete {
    &:before {
      content: " ";
      background-image: url(./../../assets/images/icons/stepper-step-complete.svg);
      background-position: center;
      background-size: 1.5rem;
      background-repeat: no-repeat;
      background-color: $success;
      border-color: $success;
    }
  }
}
