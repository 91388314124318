// PLACEHOLDER
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $grey-50;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $grey-50;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $grey-50;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $grey-50;
}

// Reset Firefox default invalid border
:not(output):-moz-ui-invalid {
  box-shadow: none;
}

.form {
  width: 100%;
  max-width: 40rem;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  float: left;
  margin-bottom: 2rem;
}

[type="search"],
[type="text"],
[type="email"],
[type="tel"],
[type="number"],
[type="url"],
[type="password"],
select,
textarea {
  @include border-radius(3px);
  width: 100%;
  padding: 0.75rem;
  background-color: $grey-05;
  border: 1px solid $grey-50;
  line-height: 1;
  transition: all 0.1s ease;

  &:focus,
  &:active {
    outline: 0;
    border-color: $grey-light;
    box-shadow: 0 0 0 1px $grey-light;
  }

  &:disabled {
    color: $grey;
  }
}

.react-select {
  input {
    &:focus,
    &:active {
      outline: 0;
      border: 0;
      box-shadow: none;
    }
  }
}

select {
  appearance: none;
  padding-right: 2.5rem;
  background-color: $grey-05;
  background-image: url(~bootstrap-icons/icons/chevron-down.svg);
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 0.75rem;
}

// Hide the menu icon pseudo element from IE 11 and IE 10
select::-ms-expand {
  display: none;
}

// Remove the dotted outline from select box from Firefox
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

option:not(:checked) {
  color: currentColor; /* prevent <option>s from becoming transparent as well */
}

.search-input {
  position: relative;

  &::placeholder {
    color: $grey;
  }
}

.search-input__icon {
  position: absolute;
  left: 0.25rem;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 0.5rem;
  color: $grey;
  transform: fill 0.25s ease-in-out;
}

[type="search"] {
  width: 7.5rem;
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  background: #fff;
  border: 0;
  border-radius: 0;
  transition: width 0.2s ease;

  &:focus {
    width: 15rem;
    box-shadow: none;
    border-bottom: 2px solid $grey-50;

    & + .search-input__icon {
      color: $grey;
    }
  }
}

[type="checkbox"],
[type="radio"] {
  width: 1.25rem;
  height: 1.25rem;
  appearance: none;
  padding: 0;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $black;
  background-color: #fff;
  border: 2px solid $grey-light;
  transition: border-color 0.15s;

  &:hover,
  &:focus,
  &:active {
    border-color: $black;
  }
}

[type="checkbox"] {
  @include border-radius;

  &:checked {
    background-image: url(./../../assets/images/icons/checkmark.svg);
  }
}

[type="radio"] {
  border-radius: 100%;

  &:checked {
    border-color: $black;
    background-image: url(./../../assets/images/icons/radio-checked.svg);
  }
}

[type="checkbox"] + label,
[type="radio"] + label {
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
}

.radio-button,
.checkbox {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;

  [type="radio"],
  [type="checkbox"] {
    flex-shrink: 0;
    margin-right: 1rem;
  }

  // & + & {
  //   margin-top: 1rem;
  // }
}

.form-group {
  // & + & {
  //   margin-top: 1.5rem;
  // }
  margin-bottom: 1.5rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: $grey;
  }

  [type="search"],
  [type="text"],
  [type="email"],
  [type="tel"],
  [type="number"],
  [type="url"],
  [type="password"],
  select,
  textarea {
    width: 100%;
    background-color: $grey-05;
    border-color: $grey-30;
    font-weight: 500;
    text-overflow: ellipsis;
  }

  .error {
    color: $alert;
    margin-top: 0.5rem;
    font-size: 14px;
  }
}

.form--inline {
  legend {
    @extend .h4;
    width: 100%;
    padding: 1rem;
    background-color: $grey-10;
  }
}

.form-group--inline {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &:last-of-type {
    border-bottom: 0;
  }

  > * {
    flex: 1;
    margin: 0.5rem;
  }

  label {
    flex: 1;
    text-align: right;
    font-size: 0.875rem;
    font-weight: 700;
  }

  input,
  textarea {
    flex: 2;
    font-size: 0.9375rem;

    &:read-only {
      border: 0;
      resize: none;
    }
  }

  textarea {
    padding: 0;
    line-height: 1.5;
    overflow: scroll;
  }
}

// .input--currency {
//   padding-left: 1.5rem;
//   background-image: url(~bootstrap-icons/icons/currency-dollar.svg);
//   background-repeat: no-repeat;
//   background-position: 0.5rem center;
//   background-size: 1rem;
// }

.switch {
  margin-top: 0.5rem;
  padding-bottom: 0.25rem;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked ~ label {
      &:before {
        background-color: tint($black, 40%);
      }

      &:after {
        background-color: $black;
        transform: translateX(3rem);
      }
    }
  }

  label {
    display: block;
    position: relative;
    padding-left: 3.75rem;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      display: block;
      width: 2rem;
      height: 0.25rem;
      pointer-events: all;
      background-color: tint($grey-50, 40%);
      border: 0;
      border-radius: 0.125rem;
      transform: translateY(-0.1rem);
      transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1.25rem;
      height: 1.25rem;
      background-color: $grey-50;
      border-radius: 0.5rem;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
      transition: transform 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out;
    }
  }
}
