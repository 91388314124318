.stp-details {
  .page-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $grey-30;
    margin: 0 -2.5rem;
    padding: 0 2.5rem;

    >* {
      margin-bottom: 1.25rem;
    }
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 -1rem;

    >* {
      flex: 1;
      margin-bottom: 1rem;
      padding: 0 1rem 1rem;
    }

    @media (min-width: $bp-lg) {
      flex-direction: row;

      .main-container {
        flex: 3;
        margin-bottom: 0;
      }

      .aside-container {
        // flex: 2;
        padding-left: 2.5rem;
        // padding-right: 2.5rem;
        border-left: 1px solid $grey-30;
        margin-bottom: 0;
      }
    }

    @media (min-width: $bp-xl) {
      .main-container {
        flex: 3;
      }

      .aside-container {
        flex: 1;
      }
    }
  }

  // Selected file data summary
  .file-summary {
    max-width: 25rem;
    @include border-radius(1rem);
    background-color: $grey-10;
    // border: 1px solid $grey-30;

    h1 {
      font-size: 1rem;
      margin: 0;
      text-align: center;
      font-family: $body-font-family;
      font-weight: bold;
      letter-spacing: -0.5px;
      word-break: break-word;
    }

    >div {
      width: 100%;
      padding: 1rem 2rem;
      // border-bottom: 1px solid $grey-30;

      &:not(:last-of-type) {
        border-bottom: 1px solid $grey-30;
      }
    }

    .split {
      display: flex;
      padding: 0;

      div {
        padding: 1rem 2rem;
        flex: 1;
      }

      div:not(:last-of-type) {
        border-right: 1px solid $grey-30;
      }

      .number {
        font-family: $display-font-family;
        font-size: 1.5rem;
        display: block;
        font-weight: 500;
        margin-bottom: 4px;
      }
    }

    dt,
    dd {
      display: inline-block;
    }

    dt {
      width: 50%;
      font-size: 0.875rem;
      // color: $grey;
      padding: 0.5rem 0;

      @media (min-width: $bp-xl) {
        width: 60%;
      }
    }

    dd {
      width: 50%;
      font-weight: bold;
      text-align: right;
      padding: 0.5rem 0;
      font-size: 0.875rem;

      @media (min-width: $bp-xl) {
        width: 40%;
      }
    }

    .total {

      dt,
      dd {
        font-weight: bold;
        font-size: 1rem;
        color: $black;
        padding: 0;
      }
    }
  }

  .section {
    margin-top: 2.5rem;

    &+& {
      margin-bottom: 3rem;
    }

    .download-errors-csv {

      .vr,
      .splitter {
        margin-right: 10px;
      }

      .button,
      .button--exact-width {
        font: 16px Roboto;
        color: #0759A4;
      }
    }
  }

  h1 {
    margin-bottom: 2.5rem;

    .pill {
      font-size: 14px;
    }
  }

  .section h2 {
    position: relative;
    margin-bottom: 2rem;
  }

  .report-attribute {
    display: flex;
    margin-bottom: 1rem;
    line-height: 1.5;

    >span:first-of-type {
      flex-basis: 40%;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .links {
    list-style: inside;

    li+li {
      margin-top: 1rem;
    }

    a {
      color: $blue-dark;
      text-decoration: underline;
    }
  }

  .validation-list {
    list-style: disc;
    font-size: 1rem;

    h5 {
      margin-bottom: 0.25rem;
    }

    li+li {
      margin-top: 1.25rem;
    }

    p {
      margin-top: 0.625rem;
      font-size: 0.875rem;
    }
  }

  fieldset+fieldset {
    border-top: $border;
  }

  .fieldset {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;

    >div:first-child {
      margin-right: 1.25rem;
    }

    >div:last-child {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      margin-right: -1.25rem;
      margin-left: -1.25rem;
    }

    legend {
      margin-bottom: 1.25rem;
      font-size: 1rem;
      font-weight: 400;
      color: $blue;
    }

    .form-group {
      flex: 0 0 100%;
      margin-bottom: 1.25rem;
      padding: 0 1.25rem;

      @media (min-width: $bp-md) {
        flex: 0 0 50%;

        &.is-threeQuaters {
          flex: 0 0 75%;
        }
      }
    }

    @media (min-width: $bp-md) {
      & {
        flex-direction: row;
      }

      >div:first-child {
        width: 12rem;
      }
    }

    @media (min-width: $bp-xl) {
      >div:first-child {
        min-width: 18rem;
      }
    }

    &.justify-right {
      justify-content: flex-end;
    }
  }

  .form-group {
    font-size: 0.875rem;

    label {
      display: block;
      margin-bottom: 0.25rem;
      color: $grey;
    }

    input {
      width: 100%;
      background-color: #fbfbfb;
      border-color: transparent;
      font-weight: 500;
    }
  }

  .accordion__trigger {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .accordion__title .Icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.25rem;
    vertical-align: top;
  }

  .expand-trigger {
    transition: all 0.1s ease-in-out;

    &.is-expanded {
      transform: rotate(90deg);
      color: $blue;
    }
  }

  .main-container .alert--failure {
    margin-bottom: 2rem;
    background-color: #fff;
  }
}

.new-log {
  display: flex;
  margin-bottom: 1.25rem;

  input {
    flex: 1;
  }

  .button {
    margin-left: 0.625rem;
  }
}

.actions {
  ul li {
    display: inline-block;
    margin-right: 1.25rem;
    margin-bottom: 0.625rem;
  }
}

.ValidationResult {
  margin-bottom: 1.25rem;
}

.Process-logs {
  .form-group input {
    border-color: $grey-50;
  }

  h5 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }

  ul {
    margin-left: 21.5rem;
    // list-style: disc;

    li {
      padding-bottom: 2rem;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0.375rem;
        left: -1.5rem;
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 50%;
        background-color: $grey-50;
        z-index: 1;
      }

      &:first-child:before {
        background-color: $grey;
      }

      &:not(:last-child):after {
        content: "";
        position: absolute;
        top: 1.25rem;
        left: -1.25rem;
        width: 1px;
        height: calc(100% - 1.25rem);
        background-color: $grey-50;
      }

      span {
        text-transform: capitalize;
        font-weight: 500;
      }
    }

    div {
      font-size: 0.75rem;
      color: $grey;

      em {
        color: $black;
        font-style: normal;
      }
    }
  }
}

.Process-details {
  >div {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;
  }

  dt {
    margin-bottom: 0.25rem;
    font-weight: 300;
    word-break: break-all;

    &::after {
      content: ": ";
    }
  }

  dd {
    font-weight: 500;

    small {
      display: block;
      margin-top: 0.25rem;
      font-weight: 400;
      color: $grey;
    }
  }

  @media (min-width: $bp-sm) {
    >div {
      flex-direction: row;
    }

    dt {
      min-width: 18rem;
    }
  }
}

.Payee-expand-panel {
  background: $grey-10;

  &[aria-hidden="true"] {
    display: none;
  }

  td {
    padding-top: 0.5rem;
    padding-bottom: 1.25rem;
  }
}

.Payee-table {
  tr {
    position: relative;
    transition: all 0.15s ease-in-out;

    &.is-expanded {
      background: $grey-10;

      td {
        border-bottom-color: transparent;
      }
    }

    &.has-error {
      border-left: 4px solid $red;
    }
  }

  @media (min-width: $bp-lg) {
    table-layout: auto;
  }
}

.Payee-tablist {
  width: 100%;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $grey-50;
  }

  li {
    position: relative;
    display: inline-block;
    padding: 1rem 1.25rem;
    text-align: center;
    color: $grey;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.2s ease-in-out;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $blue;
      transform: translateX(-100%);
      transition: transform 0.2s ease-in-out;
      z-index: 1;
    }

    &:hover {
      color: $black;
    }

    &.is-selected {
      color: $black;

      &:after {
        transform: translateX(0);
      }
    }

    &:first-child.is-selected {
      border-left-width: 0;
    }

    &:last-child.is-selected {
      border-right-width: 0;
    }
  }

  sup {
    display: inline-block;
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 100%;
    background: $red;
    color: #fff;
    line-height: 0.875rem;
    font-weight: 400;
  }
}

.Payee-tabpanel {
  display: none;
  padding: 2.5rem 1.25rem 1.25rem;

  &.is-selected {
    display: block;
    background: #fff;
  }
}

.Payment-item-list {
  display: block;
  width: 100%;
  margin-bottom: 2rem;
  padding-left: 2rem;
  counter-reset: list;

  li {
    position: relative;

    &:before {
      counter-increment: list;
      content: counter(list);
      position: absolute;
      top: 0;
      left: -0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $red;
      width: 1rem;
      height: 1rem;
      border-radius: 100%;
      color: $red;
      font-weight: 400;
    }

    &:after {
      content: "";
      position: absolute;
      width: calc(100% - 1.25rem);
      margin-right: 1.25rem;
      border-bottom: 1px dashed $red;
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    >div {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .Remove-button {
    color: $red;
  }
}